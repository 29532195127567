<script lang="ts" setup>
import type { NavigationLink } from '@/types'
import { useAvailableLocalePath } from '@/composables/useAvailableLocalePath'

interface Props {
  link: NavigationLink
  isNew: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'change-tab'): void
  (e: 'close-tab', path?: string): void
}>()

const router = useRouter()
const navStore = useNavStore()
const { currentLanguageClass } = useCurrentLanguageClass('navigation-link')
const { availableLocaleRoute } = useAvailableLocalePath()

const comp = computed(() => {
  if (props.link.href || props.link.to) {
    // OR return resolveComponent('NuxtLink')
    return defineNuxtLink({})
  }

  return 'div'
})
const isDisabled = computed(
  () => !(props.link.to || props.link.href) && 'navigation-link--disabled',
)
const linkTarget = computed(() => (props.link.newWindow ? '_blank' : '_self'))
const withSeparator = computed(
  () => props.link.withSeperator && 'with-separator',
)
const withoutDescription = computed(
  () => !props.link.description && 'navigation-link--without-description',
)
const isLinkToCareers = computed(() => props.link.to === '/careers')
const hasOffers = computed(
  () => !!(isLinkToCareers.value && navStore.numberOfJobPositions),
)

const path = computed(() => {
  if (props.link.href) {
    return props.link.href
  }
  return props.link.to && availableLocaleRoute(props.link.to)
})

const handleKeyDown = (_e: Event) => {
  props.link.to && router.push(props.link.to)
  emit('close-tab')
}

const handleTouch = () => {
  if (comp.value === 'div') {
    return
  }
  const link = props.link.to ?? props.link.href
  emit('close-tab', link)
}

const handleFocusOut = (e: FocusEvent) => {
  const relatedTarget = e.relatedTarget as Element | undefined

  if (relatedTarget && relatedTarget.getAttribute('data-target') === 'true') {
    emit('change-tab')
  }
}

const imageSrc = (icon: string) => {
  return `/images/icons/navigation/${icon}.svg`
}
</script>

<template>
  <li role="representation" :class="withSeparator">
    <component
      :is="comp"
      :to="path"
      :class="[
        'navigation-link',
        isDisabled,
        withSeparator,
        withoutDescription,
        currentLanguageClass,
      ]"
      :target="linkTarget"
      @click="handleTouch"
      @keydown.enter="handleKeyDown"
      @focusout="handleFocusOut"
    >
      <span v-if="isDisabled" class="sr-only">It's not clickable element</span>
      <img
        v-if="link.image"
        :src="imageSrc(link.image)"
        class="navigation-link__img"
        aria-hidden="true"
      />
      <div class="navigation-link__container">
        <span v-if="link.title" class="navigation-link__title"
          >{{ $t(`common.navigation.links.${link.title}.title`) }}
          <span v-if="link.additionalInfo" class="navigation-link__pill">{{
            $t('common.navigation.soon')
          }}</span>
          <span v-else-if="hasOffers" class="navigation-link__pill">{{
            $t('common.navigation.offers', {
              offers: navStore.numberOfJobPositions,
            })
          }}</span>
          <span v-if="isNew" class="navigation-link__pill"> New </span>
        </span>
        <span v-if="link.description" class="navigation-link__description">{{
          $t(`common.navigation.links.${link.title}.description`)
        }}</span>
      </div>
      <span v-if="!isDisabled" class="navigation-link__arrow" />
    </component>
  </li>
</template>

<style lang="scss" scoped>
li.with-separator {
  padding-top: var(--link-padding-top);
  position: relative;

  --space: calc(16px - var(--link-padding-bottom));

  &::before {
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--gray-300);
  }
}

.navigation-link {
  $this: &;
  display: flex;
  align-items: center;
  color: var(--color-text);
  text-decoration: none;
  padding: var(--link-padding-top) var(--link-padding-inline)
    var(--link-padding-bottom);
  position: relative;
  border-radius: var(--border-radius-base);
  gap: 16px;

  &:focus {
    outline: 2px solid var(--cobalt-600);
  }

  &__container {
    display: grid;
    flex: 1 0;
  }

  &__img {
    width: 40px;
    aspect-ratio: 1;
  }

  &__title {
    @include paragraph-s;

    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 21px;
    color: var(--color-text);
  }

  &__description {
    @include paragraph-2xs;

    color: var(--color-text);
  }

  &__arrow {
    opacity: var(--arrow-opacity, 1);
    width: 10px;
    height: 10px;
    transition: opacity 350ms ease-in-out;
    transform: rotate(180deg);
    background-image: url('@/assets/icons/16-chevron.svg?url');
  }

  &__pill {
    font-size: 10px;
    line-height: 16px;
    padding: 2px 8px;
    background-color: var(--pill-bg-color, var(--cobalt-600));
    color: var(--pill-text-color, var(--white));
    border-radius: 8px;
  }

  @media (min-width: $breakpoint-lg) {
    --arrow-opacity: 0;
  }

  &:hover {
    @media (min-width: $breakpoint-lg) {
      background-color: var(--element-bg-color, var(--paper-100));

      --arrow-opacity: 1;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;

    --pill-bg-color: #f3f4f6;
    --pill-text-color: #112950;
    --arrow-opacity: 0;

    &:hover {
      --arrow-opacity: 0;
      --element-bg-color: transparent;
    }
  }

  &--without-description {
    --link-padding-top: 4px;
    --link-padding-bottom: 4px;
    --element-bg-color: #{var(--paper-300)};
  }

  &--de#{$this}--disabled {
    #{$this}__description {
      width: 90%;
    }
  }
}
</style>

<script setup lang="ts">
import NavigationLink from './NavigationLink.vue'
import routes from '@/data/navigation-routes'
import { getCurrentLocale } from '@/utils/helpers'
import { NEW_PILL_NAMES } from '@/data/constants'

interface Props {
  isOpen: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'close', route?: string): void }>()
const i18n = useI18n()
const store = useMainStore()
const currentPromoType = computed(
  () => store.promo?.story.content.promo_type || '',
)

const displayClass = computed(
  () => props.isOpen && 'navigation-mobile-content--open',
)

const language = getCurrentLocale(i18n)

const handleCloseNavigation = (path?: string) => {
  emit('close', path)
}

const opened = (_event: number, id: string) => {
  const item = document.getElementById(id) as HTMLDivElement

  item.scrollIntoView({
    behavior: 'smooth',
    inline: 'start',
    block: 'start',
  })
}

const { locales, locale } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const currentLocale = computed(() =>
  locales.value.find((l) => l.code === locale.value),
)

const availableLocales = computed(() => {
  return locales.value.filter((l) => l.code !== locale.value)
})

const { availableLanguages } = storeToRefs(store)

const storyblokLocales = computed(() => {
  const locales = ['en']
  store.storyData.translated_slugs?.forEach(
    (i) => !!i.name && locales.push(i.lang),
  )
  return locales
})

const isDisabled = (code: string) => {
  if (availableLanguages.value !== null) {
    return !availableLanguages.value.includes(code)
  }
  return !storyblokLocales.value.includes(code)
}
</script>

<template>
  <section :class="['navigation-mobile-content', displayClass]">
    <header class="navigation-mobile-content__header">
      <button
        type="button"
        name="close navigation button"
        class="navigation-mobile-content__button"
        :aria-expanded="isOpen"
        aria-controls="primary-navigation-mobile"
        @click="handleCloseNavigation()"
      >
        <span class="sr-only">Menu</span>
        <img
          aria-hidden="true"
          role="presentation"
          src="@/assets/icons/close-icon-cobalt.svg?url"
          alt=""
        />
      </button>
    </header>
    <section class="navigation-mobile-content__menu">
      <Accordion is-in-mobile-navbar>
        <div v-for="route in routes" :id="route.tabName" :key="route.tabName">
          <AccordionItem
            hide-shadows
            is-in-mobile-navbar
            :accordion-header="
              $t(`common.navigation.tab_names.${route.tabName}`)
            "
            @opened="opened($event, route.tabName)"
          >
            <template v-if="route.sections" #accordion-content>
              <div class="navigation-mobile-content__item | padding-flow">
                <div
                  v-for="section in route.sections"
                  :key="section.title"
                  class="spacing-reset | navigation-mobile-content__menu-section"
                >
                  <h6>
                    {{
                      $t(`common.navigation.sections_names.${section.title}`)
                    }}
                  </h6>
                  <NavigationLink
                    v-for="link in section.links"
                    :key="link.title"
                    :is-new="
                      link.title ===
                      NEW_PILL_NAMES[
                        currentPromoType as keyof typeof NEW_PILL_NAMES
                      ]
                    "
                    :class="[
                      'navigation-mobile-content__navigation-link',
                      link.withSeperator &&
                        'navigation-mobile-content__navigation-link--with-seperator',
                    ]"
                    :link="link"
                    @close-tab="handleCloseNavigation(link.to ?? link.href)"
                  />
                </div>
              </div>
            </template>
          </AccordionItem>
        </div>
        <div>
          <AccordionItem
            hide-shadows
            is-in-mobile-navbar
            :accordion-header="$t(`common.navigation.languages`)"
            @opened="opened($event, 'languages')"
          >
            <template #accordion-header-icon>
              <div class="navigation-mobile-content__planet" />
            </template>
            <template #accordion-content>
              <div class="navigation-mobile-content__item | padding-flow">
                <ul class="navigation-mobile-content__lang-list">
                  <li class="navigation-mobile-content__lang-item">
                    <nuxt-link
                      v-if="currentLocale"
                      :to="
                        switchLocalePath(currentLocale && currentLocale.code)
                      "
                      class="navigation-mobile-content__lang-link navigation-mobile-content__lang-link--current"
                    >
                      {{ currentLocale && currentLocale.code }}
                    </nuxt-link>
                  </li>
                  <li
                    v-for="lang in availableLocales"
                    :key="lang.code"
                    class="navigation-mobile-content__lang-item"
                  >
                    <nuxt-link
                      :to="switchLocalePath(lang.code)"
                      :class="[
                        'navigation-mobile-content__lang-link',
                        {
                          'navigation-mobile-content__lang-link--disabled':
                            isDisabled(lang.code),
                        },
                      ]"
                    >
                      {{ lang.code }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </template>
          </AccordionItem>
        </div>
        <div class="navigation-mobile-content__cta-container">
          <BaseButton
            :title="$t('common.navigation.cta')"
            full-width
            :href="{ cached_url: `${language}/get-in-touch` }"
          >
            <template #text>
              {{ $t('common.navigation.cta') }}
            </template>
          </BaseButton>
        </div>
      </Accordion>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.navigation-mobile-content {
  --header-height: 72px;

  background: var(--white);
  top: 0;
  z-index: 9999;
  width: 100%;
  position: fixed;
  bottom: 0;
  flex-direction: column;
  display: none;

  &--open {
    display: flex;
  }

  &__menu {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(var(--app-height) - var(--header-height));
  }

  &__header {
    padding: 28px var(--space32);
    height: var(--header-height);
    border-bottom: 1px solid var(--gray-100);
    position: relative;
    flex-shrink: 0;
  }

  &__item {
    padding: 40px 32px;

    --space: 40px;

    background: var(--paper-100);
  }

  &__button {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 14px;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
  }

  &__link {
    display: block;
    padding: 28px 32px;
    font-size: var(--font-size-heading-5);
    font-weight: var(--font-weight-paragraph-bold);
    height: 80px;
    text-decoration: none;
    color: var(--cobalt-800);
    border-bottom: 1px solid var(--gray-100);
  }

  &__menu-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__cta-container {
    margin: var(--space32);
  }

  &__heading {
    @include heading-subheading;

    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-300);
  }

  &__links-container {
    display: flex;
    flex-direction: column;
  }

  &__navigation-link {
    &--with-seperator {
      --link-padding-top: 16px;
    }
  }

  &__lang-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__lang-link {
    padding-left: 42px;
    position: relative;
    text-transform: uppercase;
    color: var(--cobalt-800);

    &--disabled {
      color: var(--gray-400);
      cursor: not-allowed !important;
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }

    &--current {
      &::before {
        left: 0;
        top: 45%;
        transform: translateY(-50%);
        position: absolute;
        width: 20px;
        height: 20px;
        content: '';
        background-image: url('@/assets/icons/16-list-benefit-cobalt.svg');
        background-size: cover;
      }
    }
  }

  &__planet {
    width: 24px;
    height: 24px;
    transition: background-image 0.2s ease;
    background-image: url('@/assets/icons/language-navy.svg');
    margin-right: 14px;
  }
}
</style>
